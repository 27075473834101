import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

import Button from '../../../components/Button';
import Form from "../../../components/Form/Form";
import LabelField from "../../../components/Form/Fields/LabelField";
import TextField from "../../../components/Form/Fields/TextField";
import SelectWithInputField from "../../../components/Form/Fields/SelectWithInputField";
import config from "../../../config/config";
import { setIsSubmissionUpdating, updateSubmissionMetric } from "../../actions/mark";
import { setNotification } from "../../../actions/global";

const Details = ({
    title,
    date,
    schoolName,
    studentName,
    isPieceShowing,
    onPieceClick,
    text,
}) => {

    const {
        currentSubmission,
    } = useSelector(state => state.mark);

    const onButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        onPieceClick();
    };

    const { paragraphSelectOptions } = config;

    const dispatch = useDispatch();

    /**
     * Update Submission with given metric.  Creates a Promise object and waits for the submission to be updated before
     * saving to server.
     * @param field
     * @param value
     */
    const onUpdateSubmissionMetric = (field, value) => {
        new Promise((resolve, reject) => {
            resolve(dispatch(updateSubmissionMetric(field, value)));
        }).then(() => {
            dispatch(setIsSubmissionUpdating(true));
        }).catch((error) => {
            dispatch(setNotification(error.message, 'error'));
        });
    }

    return (
        <div className="details">
            <div className="details-header">
                <span>{title}: {date}</span>
                <Button color="gold" onClick={onButtonClick}>{isPieceShowing ? "Hide" : "Show"} Whole Piece</Button>
                {studentName} - {schoolName}
            </div>
            <div className={"md-grid md-grid--no-spacing details-content"}>
                <div
                    className={"md-cell md-cell--8 details-text" + (isPieceShowing ? " details-text-nice-display" : "")}
                    {...isPieceShowing && { style: { whiteSpace: 'break-spaces' } }}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
                <div className="md-cell md-cell--4 details-metrics">
                    <Form
                        disableStore={true}
                        onFieldChange={onUpdateSubmissionMetric}
                        name="PieceMetricsMarkerForm"
                        values={currentSubmission}
                    >
                        <div className="md-grid field-group">
                            <div className="md-cell md-cell--4">Metric Name</div>
                            <div className="md-cell md-cell--4">API</div>
                            <div className="md-cell md-cell--4">Human</div>

                            <div className="md-cell md-cell--4 md-cell--middle">
                                <LabelField name="CoherenceLabel" label="Coherence" />
                            </div>
                            <div className="md-cell md-cell--4">
                                <TextField name="OriginalCoherence" label="" disabled />
                            </div>
                            <div className="md-cell md-cell--4">
                                <SelectWithInputField
                                    name="Coherence"
                                    label=""
                                    listItems={paragraphSelectOptions}
                                />
                            </div>
                            <div className="md-cell md-cell--4 md-cell--middle">
                                <LabelField name="RepetitionLabel" label="Repetition" />
                            </div>
                            <div className="md-cell md-cell--4">
                                <TextField name="OriginalRepetition" label="" disabled />
                            </div>
                            <div className="md-cell md-cell--4">
                                <SelectWithInputField
                                    name="Repetition"
                                    label=""
                                    listItems={paragraphSelectOptions}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
};

Details.propTypes = {
    instructions: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    schoolName: PropTypes.string,
    studentName: PropTypes.string,
    children: PropTypes.node,
    onExpandClick: PropTypes.func,
}

export default Details;
