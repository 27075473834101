export const actionTypes = {
    SET_SENTENCE_TO_DELETE: 'SET_SENTENCE_TO_DELETE',
    SET_SENTENCE_TO_MERGE: 'SET_SENTENCE_TO_MERGE',
    SET_LOADING: 'SET_LOADING',
    SET_SENTENCE_TYPE_CHECK_FAIL: 'SET_SENTENCE_TYPE_CHECK_FAIL',
    UPDATE_TIMER: 'UPDATE_TIMER',
    TOGGLE_IS_PIECE_SHOWING: 'TOGGLE_IS_PIECE_SHOWING',
};

export const setSentenceToDelete = sentenceToDelete => ({
    type: actionTypes.SET_SENTENCE_TO_DELETE,
    sentenceToDelete,
});

export const setSentenceToMerge = (sentenceToMerge, mergeDirection) => ({
    type: actionTypes.SET_SENTENCE_TO_MERGE,
    sentenceToMerge,
    mergeDirection,
});

export const setLoading = isLoading => ({
    type: actionTypes.SET_LOADING,
    isLoading,
});

export const updateSentenceTypeCheckFail = sentenceTypeCheckFail => ({
    type: actionTypes.SET_SENTENCE_TYPE_CHECK_FAIL,
    sentenceTypeCheckFail,
});

export function updateTimer(time) {
    return {
        type: actionTypes.UPDATE_TIMER,
        time,
    };
}

export const toggleIsPieceShowing = isPieceShowing => ({
    type: actionTypes.TOGGLE_IS_PIECE_SHOWING,
    isPieceShowing,
});
