import React, { useState, useRef } from 'react';
import { autoUpdate, flip, FloatingPortal, useFloating } from "@floating-ui/react";
import PropTypes from 'prop-types';

import { defaultProps, getContainerClass, getFieldClass, getLabel } from '../config';
import { blockUnwantedKeyEvt } from "../../../includes/utils";

const SelectWithInputField = ({
    containerClass,
    fieldClass,
    listItems,
    onChange,
    optionValue,
    fieldRef,
    value,
    isTypingAllowed,
    labelClass,
    modifiers,
    label,
    additionalModifiers,
    ...otherProps
}) => {
    const [isDropdownShowing, setIsDropdownShowing] = useState(false);
    const input = useRef(null);

    const setRef = ref => {
        fieldRef(ref);
        input.current = ref;
    };

    const toggleDropdown = () => setIsDropdownShowing(!isDropdownShowing);

    const onInputClick = e => {
        refs.setPositionReference(e.currentTarget);
        e.preventDefault();
        e.stopPropagation();
        toggleDropdown();
    };

    /**
     *
     * @param e
     */
    const onInputChange = (e) => {
        e.preventDefault();
        e.stopPropagation();

        onChange(e);
    };

    const onOptionClick = (e, value) => {
        input.current.value = value;
        onInputChange(e);
        toggleDropdown();
    };

    const { refs, floatingStyles } = useFloating({
        open: isDropdownShowing,
        middleware: [flip()],
        placement: "bottom-start",
        whileElementsMounted: autoUpdate,
    });

    return (
        <div className={getContainerClass(containerClass, label)}>
            <div
                className={"select-field-overlay" + (isDropdownShowing ? " select-field-overlay--show" : "")}
                onClick={toggleDropdown}
            />
            {getLabel(label, name, containerClass, fieldClass, labelClass)}
            <input
                type={'number'}
                onChange={onInputChange}
                ref={setRef}
                value={value}
                onClick={onInputClick}
                className={getFieldClass(modifiers, fieldClass, label, additionalModifiers)}
                onKeyDown={(e) => blockUnwantedKeyEvt(e, ['e', 'E', '-', '+'])}
                step={0.1}
                max={1}
                min={0}
                {...otherProps}
            />
            {isDropdownShowing && (
                <FloatingPortal>
                    <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 1 }}>
                        <div className={"select-field-dropdown"}>
                            {listItems.map((item, index) => (
                                <div
                                    key={index}
                                    onClick={(e) => onOptionClick(e, item[optionValue])}
                                    className="select-field-dropdown-item"
                                >
                                    {item[optionValue]}
                                </div>
                            ))}
                        </div>
                    </div>
                </FloatingPortal>
            )}
        </div>
    );
}

SelectWithInputField.defaultProps = {
    ...defaultProps,
    fieldClass: 'select-field',
    optionValue: 'label'
};

SelectWithInputField.propTypes = {
    onChange: PropTypes.func,
    label: PropTypes.string,
    listItems: PropTypes.array,
    optionValue: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SelectWithInputField;
