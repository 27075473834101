import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import config from '../../../config/config';

import {
    updateSentenceTypeCheckFail,
    setLoading,
    setSentenceToDelete,
    setSentenceToMerge,
    updateTimer, toggleIsPieceShowing,
} from '../../actions/submissionMarker';
import {
    addWord,
    removeWord,
    setIsSubmissionUpdating,
    updateParagraphMetric,
    updateSentenceMetric,
} from '../../actions/mark';
import SubmissionMarkerActionBar from './SubmissionMarkerActionBar';
import SubmissionMarkerSentence from "./SubmissionMarkerSentence";
import Dialog from "../../../components/Dialog";
import Button from "../../../components/Button";
import Details from './Details';
import LabelField from '../../../components/Form/Fields/LabelField';
import TextField from '../../../components/Form/Fields/TextField';
import SelectWithInputField from '../../../components/Form/Fields/SelectWithInputField';
import Form from '../../../components/Form/Form';
import useRefHolder from "../../../includes/hooks/useRefHolder";


const SubmissionMarker = ({
    assessment,
    onBack,
    onSave,
    onExit,
    onNext,
    saving,
    submission,
    onDeleteSentence,
    onMergeSentence,
    isMarking,
    currentTimeMarking,
}) => {
    const {
        sentenceToDelete,
        mergeData,
        isLoading,
        sentenceTypeCheckFail,
        timeMarking,
        isPieceShowing,
    } = useSelector(state => state.submissionMarker);

    const {
        isSubmissionUpdating,
        currentMarking,
        markingMode,
    } = useSelector(state => state.mark);

    const interval = useRef(null);
    const sentenceContainer = useRef(null);
    const prevSubmission = useRef(submission);
    const dispatch = useDispatch();

    /**
     * Updates the timer
     */
    const onUpdateTimer = useRefHolder(() => {
        let time = timeMarking;
        time++;

        dispatch(updateTimer(time));
    });

    /**
     * Starts the stopwatch, set to update every 1000 ms [1 second]
     *
     */
    const startStopwatch = () => {
        dispatch(updateTimer(currentTimeMarking < timeMarking ? timeMarking : currentTimeMarking));

        interval.current = setInterval(() => {
            onUpdateTimer.current();
        }, 1000);
    }

    useEffect(
        () => {
            dispatch(setIsSubmissionUpdating(false));

            if (isMarking) {
                startStopwatch();
            }

            return () => {
                clearInterval(interval.current);
                dispatch(updateTimer(0));
            };
        },
        [isMarking]
    );

    const prepareSubmissionData = (submission) => {
        if (isMarking) {
            submission.LengthOfMarkingTime = timeMarking;
        }

        return submission;
    }

    /**
     * Saves the marked submission.
     */
    const save = (newSubmissionData = null, prevSubmissionData = null) => {
        if (newSubmissionData === null && submission) {
            onSave(submission, null);
        } else {
            onSave(prepareSubmissionData(newSubmissionData), prevSubmissionData);
        }
    }

    /**
     * When the Submission changes, scroll the sentence container back to the top.
     */
    const onSubmissionChange = () => {
        if (isMarking) {
            dispatch(updateTimer(currentTimeMarking));
        }
    }

    useEffect(
        () => {
            if (isSubmissionUpdating) {
                save(submission, prevSubmission.current);
            }

            if (submission && prevSubmission.current && submission.id !== prevSubmission.current.id) {
                onSubmissionChange();
            }
        },
        [isSubmissionUpdating, submission]
    );

    /**
     * Scrolls to the bottom of the sentence.
     *
     * @param elSentence
     */
    const onScrollSentence = (elSentence) => {
        const containerBottom = sentenceContainer.current.scrollTop + sentenceContainer.current.offsetHeight;

        // want to scroll to the bottom of the div
        const sentenceOffset = elSentence.offsetTop + elSentence.offsetHeight;

        // add animation
        sentenceContainer.current.style.transistion = 'all 0.5s';

        // only scroll if bottom is off screen, scroll so bottom of div is at bottom of container
        // add 10px padding
        if (sentenceOffset > containerBottom) {
            sentenceContainer.current.scrollTop = sentenceOffset - sentenceContainer.current.offsetHeight + 10;
        }
    }

    const onUpdateParagraphMetric = (metric, paragraph, value) => {
        dispatch(updateParagraphMetric(metric, paragraph, value));
        dispatch(setIsSubmissionUpdating(true));
    };

    /**
     * Updates a sentence metric for a submission in the store. If accumulator is passed this field is updated on
     * the submission.
     *
     * @param metric
     * @param paragraph
     * @param sentence
     * @param value
     * @param accumulator
     */
    const onUpdateSentenceMetric = (metric, paragraph, sentence, value, accumulator = null) => {
        dispatch(updateSentenceMetric(metric, paragraph, sentence, value, accumulator));
        dispatch(setIsSubmissionUpdating(true));
    }

    /**
     * Adds a word to a list for a sentence.
     *
     * @param field
     * @param list
     * @param accumulator
     * @param index
     * @param word
     * @param paragraph
     * @param sentence
     */
    const onAddWord = (field, list, accumulator, index, word, paragraph, sentence) => {
        dispatch(addWord(field, list, accumulator, index, word, paragraph, sentence));
        dispatch(setIsSubmissionUpdating(true));
    }

    /**
     * Removes a word from a list for a sentence.
     *
     * @param field
     * @param list
     * @param accumulator
     * @param index
     * @param paragraph
     * @param sentence
     */
    const onRemoveWord = (field, list, accumulator, index, paragraph, sentence) => {
        dispatch(removeWord(field, list, accumulator, index, paragraph, sentence));
        dispatch(setIsSubmissionUpdating(true));
    }

    /**
     * Gets the list of "word lists" with settings to be used for updating the lists in the store.
     *
     * @param paragraph
     * @param sentence
     * @returns {*}
     */
    const getWordLists = (sentenceMetrics, paragraph, sentence) => {
        // reduce word list config, adding event handlers
        return sentenceMetrics.reduce((returnObj, wordType) => {
            return !wordType.list ? returnObj : Object.assign({}, returnObj, {
                [wordType.key]: {
                    words: sentence[wordType.list],
                    label: wordType.label,
                    onAdd: (index, word) => onAddWord(
                        wordType.field,
                        wordType.list,
                        wordType.accumulator,
                        index,
                        word,
                        paragraph,
                        sentence
                    ),
                    onRemove: (index) => onRemoveWord(
                        wordType.field,
                        wordType.list,
                        wordType.accumulator,
                        index,
                        paragraph,
                        sentence
                    ),
                    multiWord: wordType.multiWord,
                }
            });
        }, {});
    }

    const onSentenceToDelete = (sentence) => {
        dispatch(setSentenceToDelete(sentence));
    }

    const onDeleteSentenceClick = (sentence) => {
        dispatch(setLoading(true));
        onDeleteSentence(sentence).finally(() => {
            dispatch(setLoading(false));
        });
        dispatch(setSentenceToDelete(null));
    }

    const onSentenceToMerge = (sentence, mergeDirection) => {
        dispatch(setSentenceToMerge(sentence, mergeDirection));
    }

    const onMergeSentenceClick = (mergeData) => {
        dispatch(setLoading(true));
        onMergeSentence(mergeData).finally(() => {
            dispatch(setLoading(false));
        });
        dispatch(setSentenceToMerge(null, null));
    }

    /**
     * Compare objects and return number for sorting.
     * @param {Object} v1 object 1
     * @param {Object} v2 object 2
     * @param {string} value the value of the objects to compare against each other.
     * @returns {number}
     */
    const compareObjects = (v1, v2, value) => {
        if (v1[value] < v2[value]) {
            return -1;
        } else if (v1[value] > v2[value]) {
            return 1;
        } else return 0;
    }

    /**
     * Check if all the sentence styles [SASS] have been identified.
     * If yes, go to next submission. If not, warn marker.
     * @param submission
     */
    const onSentenceCheck = (submission) => {
        Object.values(submission.paragraphs).map(paragraph => {
            Object.values(paragraph.sentences).map(sentence => {
                if (sentence.SASS === null ||
                    sentence.SASS === "" ||
                    sentence.SASS === 0 ||
                    sentence.SASS === "0"
                ) {
                    dispatch(updateSentenceTypeCheckFail(true));

                    return;
                }
            })
        });

        onNext(prepareSubmissionData(submission));
    }

    const onSentenceCheckDialogNext = (submission) => {
        dispatch(updateSentenceTypeCheckFail(false));

        onNext(prepareSubmissionData(submission));
    }

    /**
     * Gets the time taken and outputs it as desired
     *
     * @returns {string}
     */
    const prettyTimerOutput = () => {
        let time = timeMarking;
        if (!isMarking) {
            time = currentTimeMarking;
        }
        let minutes = "0";
        let seconds = "0";
        if (time > 0) {
            minutes = (Math.floor(time / 60)).toString();
            seconds = (time - (minutes * 60)).toString();
        }
        if (minutes.length === 1) {
            minutes = "0" + minutes;
        }
        if (seconds.length === 1) {
            seconds = "0" + seconds;
        }
        return minutes + ":" + seconds;
    }

    const onPieceClick = () => dispatch(toggleIsPieceShowing(!isPieceShowing));

    const { sentenceMetrics, sentenceTypes, paragraphSelectOptions } = config;

    let selectedID = 0;
    if (!!mergeData.Sentence) {
        selectedID = parseInt(mergeData.Sentence.id);
    } else if (!!sentenceToDelete) {
        selectedID = parseInt(sentenceToDelete.id);
    }

    return (
        !submission ? <p>Loading...</p> : <div className="marker">
            {isLoading && <div className="marker__overlay" />}
            <Dialog visible={!!sentenceToDelete}>
                <div className="dialog-content dialog-content--padding">Are you sure you'd like to delete this
                    sentence?
                </div>
                <div className="dialog-actions">
                    <Button
                        className="btn btn--ok btn--spacing-right" title="OK"
                        onClick={() => onDeleteSentenceClick(sentenceToDelete)}
                    >
                        OK
                    </Button>
                    <Button
                        className="btn btn--primary" title="Cancel"
                        onClick={() => onSentenceToDelete(null)}
                    >
                        Cancel
                    </Button>
                </div>
            </Dialog>

            <Dialog visible={!!mergeData.Sentence}>
                <div className="dialog-content dialog-content--padding">Are you sure you'd like to merge this
                    sentence {mergeData.MergeDirection}?
                </div>
                <div className="dialog-actions">
                    <Button
                        className="btn btn--ok btn--spacing-right" title="OK"
                        onClick={() => onMergeSentenceClick(mergeData)}
                    >
                        OK
                    </Button>
                    <Button
                        className="btn btn--primary" title="Cancel"
                        onClick={() => onSentenceToMerge(null, null)}
                    >
                        Cancel
                    </Button>
                </div>
            </Dialog>

            <Dialog visible={sentenceTypeCheckFail}>
                <div className="dialog-content dialog-content--padding">Are you sure you want to continue without
                    identifying all the sentences? Maybe you should add a note
                </div>
                <div className="dialog-actions">
                    <Button
                        className="btn btn--error btn--spacing-right"
                        title="Cancel"
                        onClick={() => dispatch(updateSentenceTypeCheckFail(false))}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn btn--ok"
                        title="Continue"
                        onClick={() => onSentenceCheckDialogNext(submission)}
                    >
                        Continue
                    </Button>
                </div>
            </Dialog>

            <SubmissionMarkerActionBar
                originalMarker={currentMarking.marker}
                onBack={() => onBack(prepareSubmissionData(submission))}
                onExit={() => onExit(prepareSubmissionData(submission))}
                onExitAndAdvance={() => onExit(prepareSubmissionData(submission), true)}
                onNext={() => onSentenceCheck(submission)}
                timer={prettyTimerOutput()}
                saving={saving}
                markingMode={markingMode}
            />

            <Details
                text={isPieceShowing ? submission.Text : currentMarking?.question?.instructions + `<p>${submission.Text}</p>`}
                title={assessment?.staffName}
                date={moment(submission.MarkedAt || submission.LastEdited).format('YYYY-MM-DD HH:mm')}
                schoolName={assessment?.school?.Name}
                studentName={currentMarking?.student?.FirstName + ' ' + currentMarking?.student?.Surname}
                isPieceShowing={isPieceShowing}
                onPieceClick={onPieceClick}
            />

            <div
                className="md-grid md-grid--no-spacing marker__columns"
                style={{ width: "100%" }}
            >
                {Object.values(submission.paragraphs)
                    .sort((v1, v2) => compareObjects(v1, v2, 'PAOrder'))
                    .map((paragraph, pIndex) => (
                        <div
                            key={`p-${pIndex}`} className="md-grid md-grid--no-spacing marker__paragraph-container"
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 410px 1fr",
                            }}
                        >
                            <div className="marker__column marker__column--extra-padding">
                                <div className="marker__text marker--text">
                                    <div className="marker__text-paragraph" key={'Paragraph_' + paragraph.id}>
                                        <p><span className="marker__text-paragraph-marker">{pIndex + 1}</span>
                                            {Object.values(paragraph.sentences)
                                                .sort((v1, v2) => compareObjects(v1, v2, 'SAOrder'))
                                                .map(sentence => {
                                                    return sentence.Text + ' '
                                                })
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={"marker__column"}>
                                <Form
                                    disableStore={true}
                                    onFieldChange={(field, value) => onUpdateParagraphMetric(field, paragraph, value)}
                                    name="SubmissionMarkerForm"
                                    values={paragraph}
                                >
                                    <div className="md-grid field-group">
                                        <div className="md-cell md-cell--4">Metric Name</div>
                                        <div className="md-cell md-cell--4">API</div>
                                        <div className="md-cell md-cell--4">Human</div>

                                        <div className="md-cell md-cell--4 md-cell--middle">
                                            <LabelField name="DevelopmentLabel" label="Development" />
                                        </div>
                                        <div className="md-cell md-cell--4">
                                            <TextField name="OriginalDevelopment" label="" disabled />
                                        </div>
                                        <div className="md-cell md-cell--4">
                                            <SelectWithInputField
                                                name="Development"
                                                label=""
                                                listItems={paragraphSelectOptions}
                                            />
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div className="marker__column" ref={sentenceContainer}>
                                <div className="marker__paragraph marker__paragraph--breakdown" key={'paragraph_' + paragraph.id}>
                                    {Object.values(paragraph.sentences)
                                        .sort((v1, v2) => compareObjects(v1, v2, 'SAOrder'))
                                        .map((sentence, sIndex, sentences) => (
                                            <SubmissionMarkerSentence
                                                sentence={sentence}
                                                paragraph={paragraph}
                                                sentenceTypes={sentenceTypes}
                                                sentenceMetrics={sentenceMetrics}
                                                key={sentence.id}
                                                onSentenceMetricChange={onUpdateSentenceMetric}
                                                onScrollSentence={onScrollSentence}
                                                wordTypes={getWordLists(sentenceMetrics, paragraph, sentence)}
                                                onSentenceToDelete={onSentenceToDelete}
                                                onSentenceToMerge={onSentenceToMerge}
                                                isFirstInSentence={sIndex === 0}
                                                isLastInSentence={sIndex === sentences.length - 1}
                                                canDelete={submission.NoS > 1}
                                                isSelected={sentence.id == selectedID}
                                                sIndex={sIndex}
                                                sl={sentences.length}
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default SubmissionMarker;
